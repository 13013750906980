import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  id: "layout"
};
import headComm from "./components/head";
import footComm from "./components/foot";
export default {
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      var _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(headComm)), _createVNode(_component_router_view), _createVNode(_unref(footComm))]);
    };
  }
};