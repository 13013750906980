import { ref } from "vue";

const news = [];
// 获取新闻
(function () {
  const files = require.context('/public/news/newsFile', false, /.json$/)
  files.keys().forEach((key) => (news.push(files(key))));
}())
let current_news = ref(0)
function currentChange (page) {
  current_news.value = page - 1
}
export default { news, current_news, currentChange }