import { onMounted, onUnmounted, ref } from "vue";
// 动态获取图片高度赋给容器
const useAutoHeight = (imgBox) => {
  let height = ref(800);
  let img = null
  function getHeight () {
    height.value = img.height;
  }
  onMounted(() => {
    img = imgBox.value.getElementsByTagName("img")[0]
    window.addEventListener("resize", getHeight);
    setTimeout(() => {
      getHeight();
    }, 500);
  });
  onUnmounted(() => {
    window.removeEventListener("resize", getHeight);
  });
  return height
}
export default useAutoHeight