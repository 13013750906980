import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// 引入动画库
import animate from 'animate.css'
// 实现动画
import addAnimate from '@/directive/addAnimate.js'
// 公共样式
import '@/assets/css/public.less'
// 引入iconfont
import '@/assets/iconfont/iconfont.css'

let app = createApp(App)
app.directive('animate', addAnimate).use(router).use(animate).mount('#app')
