const io = new IntersectionObserver((entries) => {
  entries.forEach(item => {
    // isIntersecting判断是否出现在可视区域
    if (item.isIntersecting) {
      // 停止观察
      io.unobserve(item.target);
      item.target.classList.add('animate__animated')
      item.target.classList.add(item.target.loadClass)
    }
  })
})
export default {
  created (el, binding) {
    el.loadClass = binding.value
    io.observe(el)
  },
}
