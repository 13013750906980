import "core-js/modules/es.object.to-string.js";
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6dd90f61"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  id: "headComm_box"
};
var _hoisted_2 = {
  id: "headComm",
  class: "maxWidth"
};
var _hoisted_3 = {
  class: "head_logo"
};
var _hoisted_4 = {
  class: "head_nav"
};
var _hoisted_5 = ["href"];
var _hoisted_6 = {
  class: "head_login"
};
var _hoisted_7 = ["href"];
import { ref, watch, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
export default {
  setup: function setup(__props) {
    var loginUrl = process.env.VUE_APP_LOGIN_URL;
    var nav_list = [{
      title: "首页",
      route: "/"
    }, {
      title: "产品介绍",
      route: "/introduce"
    }, {
      title: "使用手册",
      href: "https://www.yuque.com/books/share/19bf1514-b11a-417b-b5c5-5020810451f0/shnikv"
    }, {
      title: "关于我们",
      route: "/about"
    }];
    var active_nav = ref("/"); //当前选中的导航

    var route = useRoute(); //获取路由信息

    var scroll = reactive({
      left: 0,
      width: 76
    }); //导航滚动距离左边

    var getScroll = function getScroll(path) {
      active_nav.value = path;
      nav_list.forEach(function (item, index) {
        if (item.route == path) {
          scroll.left = document.getElementsByClassName("navitem")[index].offsetLeft;
          scroll.width = document.getElementsByClassName("navitem")[index].offsetWidth;
        }
      });
    };

    onMounted(function () {
      getScroll(route.path);
    });
    watch(function () {
      return route.path;
    }, getScroll);
    return function (_ctx, _cache) {
      var _component_router_link = _resolveComponent("router-link");

      var _directive_animate = _resolveDirective("animate");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_router_link, {
        to: "/"
      }, {
        default: _withCtx(function () {
          return [_withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [[_directive_animate, 'animate__flipInX']])];
        }),
        _: 1
      }), _createElementVNode("div", _hoisted_4, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(nav_list, function (i, j) {
        return _createElementVNode("div", {
          class: _normalizeClass(_unref(active_nav) == i.route ? 'active_item navitem' : 'navitem'),
          key: j
        }, [i.route ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: i.route
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(i.title), 1)];
          }),
          _: 2
        }, 1032, ["to"])) : (_openBlock(), _createElementBlock("a", {
          key: 1,
          target: "_blank",
          href: i.href
        }, _toDisplayString(i.title), 9, _hoisted_5))], 2);
      }), 64)), _createElementVNode("div", _hoisted_6, [_createElementVNode("a", {
        target: "_blank",
        href: _unref(loginUrl)
      }, "登录", 8, _hoisted_7)]), _createElementVNode("div", {
        style: _normalizeStyle({
          left: _unref(scroll).left + 'px',
          width: _unref(scroll).width + 'px'
        }),
        class: "head_scroll"
      }, null, 4)])])]);
    };
  }
};