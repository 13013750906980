import { createRouter, createWebHistory } from 'vue-router'
import layout from '@/layout/index'
import index from '@/views/index/index'
import introduce from '@/views/introduce/introduce'
import about_ from '@/views/about/about'
const routes = [
  {
    path: '/',
    name: 'layout',
    component: layout,
    children: [
      {
        path: '',
        name: '首页',
        component: index
      },
      {
        path: 'introduce',
        name: '产品介绍',
        component: introduce
      },
      {
        path: 'about',
        name: '关于我们',
        component: about_
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!from.href) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition)
        }, 50)
      })
    } else {
      return {
        top: 0
      }
    }
  },
})

router.beforeEach((to) => {
  document.title = `${to.name}-海瀚云`
})

export default router
